import React from 'react';
import { AppRoute } from "./index";
import Login from "../pages/Auth/Login";
import HomeCliente from "../pages/Client/Home";
import MeuVideo from "../pages/Client/MyVideo";
import MyProfile from "../pages/Client/MyProfile";
import ResponderEntrevista from '../pages/Client/ResponderEntrevista/Index';
import Entrevistas from '../pages/Client/Entrevistas/Index';
import RespostasEntrevista from '../pages/Client/RespostasEntrevista/Index';
import GravarPitch from '../pages/Client/GravarPitch/Index';
import News from '../pages/Client/News';
import FirstAccess from "../pages/Client/FirstAccess";
import PageInConstruction from "../components/PageInConstruction";
import CareerManagement from "../pages/Client/CareerManagement";
import Certificates from "../pages/Client/Certificates";
import Testes from "../pages/Client/Testes";
import VirtualCard from "../pages/Client/VirtualCard";


const appRoutes: AppRoute[] =  [
    {
        path: '/',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/login',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/home',
        requiresAuth: true,
        element: <HomeCliente />
    },
    {
        path: '/meu-video',
        requiresAuth: true,
        element: <MeuVideo />
    },
    {
        path: '/meu-video/pitch/gravar',
        requiresAuth: true,
        element: <GravarPitch />
    },
    {
        path: '/meu-perfil',
        requiresAuth: true,
        element: <MyProfile />
    },
    {
        path: '/entrevista-simulada',
        requiresAuth: true,
        element: <Entrevistas />
    },
    {
        path: '/entrevista-simulada/responder',
        requiresAuth: true,
        element: <ResponderEntrevista />
    },
    {
        path: '/entrevista-simulada/responder/:contagem',
        requiresAuth: true,
        element: <ResponderEntrevista />
    },

    {
        path: '/entrevista-simulada/respostas/:contagem',
        requiresAuth: true,
        element: <RespostasEntrevista />
    },
    {
        path: '/noticias',
        requiresAuth: true,
        element: <News />
    },
    {
        path: '/primeiro-acesso',
        requiresAuth: true,
        element: <FirstAccess />
    },
    {
        path: '/em-construcao',
        requiresAuth: true,
        element: <PageInConstruction />
    },
    {
        path: '/gestao-carreira',
        requiresAuth: true,
        element: <CareerManagement />
    },
    {
        path: '/certificados',
        requiresAuth: true,
        element: <Certificates />
    },
    {
        path: '/testes',
        requiresAuth: true,
        element: <Testes />
    },
{
        path: '/cartao-virtual',
        requiresAuth: true,
        element: <VirtualCard />
    },

    {
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    }
];

export default appRoutes;