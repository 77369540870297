import menus from "../../../router/menus";
import {createElement} from "react";
import {Link, useLocation} from "react-router-dom";
import communityApi from "../../../services/communityApi";
import Alert from "../../../helpers/Alert";
import Storage from "../../../helpers/Storage";
export default function SideBar() {
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];
    const userData: any = Storage.getUserData();

    const goToCommunity = async (rota?: any) => {
        if (!userData.usuario.signature){
            return Alert.error( 'Entre em contato com o suporte!', 'Usuário sem assinatura na plataforma')
        }
        const {data, isError} = await communityApi.goToCommunity(rota);
        if (!isError){
            window.open(data.url, "_blank")
            return ;
        }
        return Alert.error("Não foi possivel acessar o portal nesse momento")
    }

    return(
        <aside id="default-sidebar" className="w-80 h-[calc(100vh_-_90px)] md:block bg-white xs:hidden overflow-auto" aria-label="Sidebar">
            <div className="h-full overflow-auto bg-white text-[14px] font-normal ">
                <ul className=" ">
                    {
                        menus.map((menu, index) => {
                            if (menu.comunidade){

                                return(
                                    <button onClick={() => goToCommunity(menu.url)} className={`w-full flex items-center px-2 group ${!index ? 'mt-5' : '' }`}>
                                        <div className={`flex items-center gap-2 text-[#263465] rounded-md w-full p-3 hover:bg-[#EDEFF8] ${'/'+validatePage === menu.path ? 'bg-[#EDEFF8] font-bold ' : 'text-[#263465]'}`}>
                                            {menu.icon ? <menu.icon className={'w-[20px] h-[20px]'} /> : ''}<span className="">{menu.title}</span>
                                        </div>
                                    </button>
                                )
                            }

                            return(
                                <li>
                                    {
                                        userData.usuario.signature ?
                                            <Link to={menu.url ? menu.url : menu.path} target={menu.url ? '_blank' : '_self'} className={`flex items-center px-2 group ${!index ? 'mt-5' : '' }`}>
                                                <div className={`flex items-center gap-2 text-[#263465] rounded-md w-full p-3 hover:bg-[#EDEFF8] ${'/'+validatePage === menu.path ? 'bg-[#EDEFF8] font-bold ' : 'text-[#263465]'}`}>
                                                    {menu.icon ? <menu.icon className={'w-[20px] h-[20px]'} /> : ''}<span className="">{menu.title}</span>
                                                </div>
                                            </Link>
                                            :
                                            menu.path === '/relatorio' ?

                                                <Link to={menu.url ? menu.url : ''} target={menu.url ? '_blank' : '_self'} className={`flex items-center px-2 group ${!index ? 'mt-5' : '' }`}>
                                                    <div className={`flex items-center gap-2 text-[#263465] rounded-md w-full p-3 hover:bg-[#EDEFF8] ${'/'+validatePage === menu.path ? 'bg-[#EDEFF8] font-bold ' : 'text-[#263465]'}`}>
                                                        {menu.icon ? <menu.icon className={'w-[20px] h-[20px]'} /> : ''}<span className="">{menu.title}</span>
                                                    </div>
                                                </Link>
                                                :

                                                <button onClick={() => goToCommunity(menu.url)}  className={`w-full flex items-center px-2 group ${!index ? 'mt-5' : '' }`}>
                                                    <div className={`flex items-center gap-2 text-[#263465] rounded-md w-full p-3 hover:bg-[#EDEFF8] ${'/'+validatePage === menu.path ? 'bg-[#EDEFF8] font-bold ' : 'text-[#263465]'}`}>
                                                        {menu.icon ? <menu.icon className={'w-[20px] h-[20px]'} /> : ''}<span className="">{menu.title}</span>
                                                    </div>
                                                </button>
                                    }

                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </aside>
    )
}