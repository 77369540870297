import Select from "react-select";
import Str from "../../helpers/Str";
import {IInputSelect} from "../../types";

/*export type SelectOption<V = string, L = string> = {
    value: V;
    label: L;
}*/

export default function InputSelect({
    selected,
    className,
    margintopClassName,
    label,
    classNameContainer,
    loading,
    options,
    onChangeSelect,
    placeholder,
    isMulti,
    isOptionDisabled,
    isDisabled,
    key,
    value,
    setValue,
    disabled,
    error,
    required,
    isClearable,
    limit
}: IInputSelect){

    const inputSelectStyle = () => ({
        paddingBottom: '5px',
        paddingTop: '6px',
    });

    const meuPla =  'auto';
    /*if (menuPlacement !== undefined){
        console.log(menuPlacement);
        meuPla = menuPlacement;
    }*/

    const backgroundDifferentOptions = (isSelected: any, isFocused: any) => {
        if (isSelected && isFocused) return "#E0A422";
        if (!isSelected && isFocused) return "#E0A422";
        if (isSelected && !isFocused) return "#13131a";
        if (!isSelected && !isFocused) return "#13131a";
    };
    return(
        <div key={key} className={className ?? classNameContainer}>
            {
                label ?
                    <label className={'font-semibold'}>
                        {label}
                        {required ? <span className='text-red-600'> *</span> : ''}
                    </label>
                    :
                    ''
            }

            <Select
                /*menuPlacement={meuPla}*/
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                menuPlacement={'auto'}
                isClearable={isClearable}
                isLoading={loading}
                placeholder={placeholder ?? ''}
                value={value
                    ? (isMulti ? options.filter((opt) => value.includes(opt.value)) : options.filter((opt) => opt.value === value))
                    : (options ? options.filter((opt) => opt.value === selected)
                        : [])}
                /*value={
                    isMulti ?
                        options ? options.filter((opt) => selected.includes(opt.value)) : []
                        :
                        options ? options.filter((opt) => opt.value === selected) : []
                }*/
                className={Str.tw(
                    'mt-[0.5rem] ',
                )}
                options={options}
                onChange={setValue ? (e: any) => setValue(isMulti ? e.map((e: any) => e?.value ?? null) : e?.value ?? null) : onChangeSelect}
                isMulti={isMulti}
                /*isOptionDisabled={isOptionDisabled}*/
                isDisabled={isDisabled ?? disabled}
                isOptionDisabled={isOptionDisabled ? (option) => value.length >= 3 : (option) => false}
            />
            {!!error?.length && <span className="text-red-600 ">{error}</span>}
        </div>
    )
}