import axios from 'axios';


const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer b3c1a9f84e72d6a5f09e37b81c4d5f2a`
    }
});
//api.defaults.headers.common['Authorization'] = 'b3c1a9f84e72d6a5f09e37b81c4d5f2a';
export default {

    async getClassInfo(params?: string) {
        try {
            //return await api.get(`https://nichoos.top/public/classinfo?companyparam=agroskills&email=contato@agroskills.com.br`);
           return await api.get(`https://nichoos.top/public/classinfo?companyparam=agroskills&email=${params}`);
        } catch (err: any) {
           console.log(err)
        }

    }
}