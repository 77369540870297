
interface ICardMyCourses {
    data: any
}
export default function CardMyCourses({data}:ICardMyCourses) {
    return(
        <>
            <div className={'text-[17px] text-[#263465] font-semibold'}>
                Meus cursos
            </div>
            <div className={'w-full flex md:flex-row xs:flex-col gap-2'}>
                <img className={'object-fill rounded-lg  w-56'} src={data.imageRectangle}/>
                <div className={'flex flex-col gap-2'}>
                    <div className={'text-[14px] text-[#263465] font-bold'}>{data.name}</div>
                    <div className={'text-[12px] text-[#9299B1]'}>{data.description.split('.')[0]}</div>
                    <div className={'flex w-full gap-2'} >
                        <div className={'text-[12px] text-[#263465]'}>Progresso</div>
                        <div className={'flex items-center gap-1'}>
                            <div className={`h-[8px] md:w-[150px] xs:w-[100px] bg-[#EDEFF8]  rounded-lg`}>
                                <div className={`h-[8px] rounded-lg `} style={{width: data.progress, backgroundColor:'#71BD00'}}/>
                            </div>
                            {data.progress}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}