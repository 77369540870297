import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useEffect, useState} from "react";
import vagasApi from "../../../services/vagasApi";
import bannerApi from "../../../services/bannerApi";
export default function CardAdvertisements() {
    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const getData = async () => {
        const [bannerData] = await Promise.all([bannerApi.getBannersScreen()]);
        if (!bannerData.isError){
            setBanners(bannerData.data)
        }
        setLoading(false)
    }
    useEffect(() => {getData()},[])
    return(
        <div className={'w-full flex flex-col bg-white rounded-lg'}>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    banners.length ?
                        <Carousel
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                            responsive={responsive}
                            infinite={banners.length > 1}
                            transitionDuration={6000}
                            autoPlay
                        >
                            {
                                banners.map((banner: any) => {
                                    return <div><img className={'rounded-lg'} src={banner.image_path}/></div>

                                })
                            }

                        </Carousel>
                        :
                        <div className={'flex items-center justify-center p-3 text-[#263465]'}>
                            Nenhum banner disponível
                        </div>
            }

        </div>
    )
}