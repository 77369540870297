import PageContainer from "../../../components/container/PageContainer";
import Storage from "../../../helpers/Storage";
import noImage from '../../../assets/img/noImage.png';
import CardUser from "../../../components/cards/CardUser";
import RecentNews from "../../../components/cards/RecentNews";
import CardJobVacancies from "../../../components/cards/CardJobVacancies";
import CardAdvertisements from "../../../components/cards/CardAdvertisements";
import userApi from "../../../services/userApi";
import {useEffect, useState} from "react";
import nichosApi from "../../../services/nichosApi";
import CardMyCourses from "../../../components/cards/CardMyCourses";
//import { Bubble } from "@typebot.io/react";

const TypebotBubble = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'module';
        script.innerHTML = `import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.85/dist/web.js';

      Typebot.initBubble({
        typebot: "agroskills-1",
        apiHost: "https://api.automatebot.com.br",
        theme: {
          button: { 
              backgroundColor: "#598E71" ,
              customIconSrc: "https://i.postimg.cc/WzHPG9dr/download-2.jpg",
              size: "large",
          },
          chatWindow: { backgroundColor: "#fff" },
        },
      });
    `;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default function HomeCliente() {
    const userData: any = Storage.getUserData();
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(true);
    const [disc, setDisc] = useState([]);
    const [classes, setClasses]: any = useState([]);

    const getData = async () => {
        const [userDataApi, discData, nichosClassInfo] = await Promise.all([
            userApi.getPercentPerfil(),
            userApi.getPerfilDisc(),
            nichosApi.getClassInfo(userData.usuario.email)
        ])
        if (nichosClassInfo){
            setClasses(nichosClassInfo.data.allUserClasses)
        }
        if (!userDataApi.isError){
            setPercent(userDataApi.data);
        }
        if (!discData.isError){
            setDisc(discData.data);
        }
        setLoading(false);
    }

    useEffect(() => {getData()}, [])

    const onClick = (data: any) => {
        // @ts-ignore
        const urlCadastro = process.env.REACT_APP_API.replace('api', 'vaga/'+data.id)
        window.open(urlCadastro, "_blank")
    }
    return(
        <PageContainer>
            <div className={'w-full flex md:flex-row xs:flex-col xs:gap-4'}>
                <div className={'md:w-[65%] xs:w-full flex flex-col px-2 gap-5'}>
                    <div className={'md:text-[30px] xs:text-[20px] font-semibold text-[#263465]'}>Seja bem vindo ao portal!</div>
                    <CardUser
                        loading={loading}
                        percent={percent}
                        userData={userData.usuario}
                        disc={disc}
                    />
                    <div className={'flex flex-col gap-4 bg-white w-full p-5 border border-white rounded-lg'}>
                        {
                            loading ?
                                <span className="flex items-center justify-center h-20 p-5">
                                  <svg className="animate-spin -ml-1 mr-3 h-[15px] w-[15px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                </span>
                                :
                                <>
                                    {
                                        classes.length ?
                                            classes.map((classe: any) => {
                                                return <CardMyCourses data={classe}/>
                                            })
                                            :
                                            <div className={'flex items-center justify-center p-3 text-[#263465]'}>Nenhum curso cadastrado</div>

                                    }
                                    {/*<CardMyCourses data={}/>*/}
                                </>
                        }

                    </div>

                </div>
                <div className={'flex flex-col md:w-[35%] xs:w-full h-full gap-3'}>
                    <RecentNews/>
                    <CardJobVacancies onClick={onClick}/>
                    <CardAdvertisements/>
                </div>
            </div>

            {/*<Bubble
                typebot="agroskills-1"
                apiHost={"https://api.automatebot.com.br"}
                theme={{
                    button: {
                        backgroundColor: "#598E71",
                        customIconSrc: "https://i.postimg.cc/WzHPG9dr/download-2.jpg",
                        size: "large"
                    },
                }}
            />*/}
            <TypebotBubble/>
        </PageContainer>
    )
}