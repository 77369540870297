import Navbar from "../../../components/container/NavBar";
import Input from "../../../components/inputs/Input";
import Button from "../../../components/button/Button";
import {useEffect, useState} from "react";
import undergraduateCourseApi from "../../../services/undergraduateCourseApi";
import InputSelect from "../../../components/inputSelect";
import Form from "../../../components/form/Form";
import Storage from "../../../helpers/Storage";
import userApi from "../../../services/userApi";
import Alert from "../../../helpers/Alert";
import {useNavigate} from "react-router-dom";
import areasActivitiesApi from "../../../services/areasActivitiesApi";

export default function FirstAccess() {
    const userData: any = Storage.getUserData();
    const [state, setState] = useState({
        id: userData.usuario.id,
        fisrt_access: false,
        undergraduate_id: '',
        course_end_date: '',
        areas: [],
        perfil: '',
        difficulty_career: '',
        strong_point: '',
        weak_point: '',
        professional_goal_five: '',
        idea_finish_graduation: '',
    })
    const [loading, setLoading] = useState(true);
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [areasActivitieOptions, setAreasActivitieOptions] = useState([]);
    const [areasActivitieSelected, setAreasActivitieSelected] = useState([]);
    const navigate = useNavigate();
    const getData = async () =>{
        const [dataUndergraduateCourse, dataAreasActivities] = await Promise.all([
            undergraduateCourseApi.getList(),
            areasActivitiesApi.listAll()
        ]);
        //const {data, isError} = await  undergraduateCourseApi.getList();
        if(!dataUndergraduateCourse.isError){
            setCoursesOptions(dataUndergraduateCourse.data.map((dt:any) => {return {value: dt.id, label: dt.name}}))
        }

        if(!dataAreasActivities.isError){
            // @ts-ignore
            setAreasActivitieOptions(dataAreasActivities.data.map((dt:any) => {return {value: dt.id, label: dt.name}}))
        }
        setLoading(false);
    }

    useEffect(()=>{getData()}, [])

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    const perfilOptions = [
        {value:'Extremamente Técnico', label: 'Extremamente Técnico'},
        {value:'Comercial', label: 'Comercial'},
        {value:'Relacional', label: 'Relacional'},
        {value:'Todos', label: 'Todos'},
    ]

    const finishGraduationOptions = [
        {value: 'Deseja Empreender', label: 'Deseja Empreender'},
        {value: 'Deseja ser um professor', label: 'Deseja ser um professor'},
        {value: 'Deseja ser um pesquisador', label: 'Deseja ser um pesquisador'},
        {value: 'Deseja conseguir um emprego', label: 'Deseja conseguir um emprego'},
        {value: 'Criar uma Startup', label: 'Criar uma Startup'},
        {value: 'Não tenho a mínima ideia', label: 'Não tenho a mínima ideia'},
    ]


    async function submit() {
        state.course_end_date = state.course_end_date+'-01';
        state.areas = areasActivitieSelected;
        const {data, isError} = await userApi.update(state.id, state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }

            return Alert.error(data.message? data.message : data);
        }
        if (data.image_path){
            let user = userData.usuario;
            user.fisrt_access = false;
            Storage.setUserData({ usuario: user });
        }
        navigate('/home')
    }
    const handleChangeSelect = (value: any) => {
        setAreasActivitieSelected(value)
    };
    return(
        <div>
            <Navbar/>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    <div className={'flex justify-center gap-4 mt-5'}>
                        <div className={'flex flex-col md:px-[2rem] gap-3 mb-[20px] xs:p-2'}>
                            <div className={'text-[25px] font-semibold text-[#2C2C36]'}>
                                Seja bem vindo ao nosso sistema!
                            </div>
                            <div>
                                Primeiro responda nosso questionário para ter acesso ao sistema completo.
                                <p/>
                                Vamos lá!!
                            </div>
                            <Form className={''} onSubmit={() => submit()}>
                                <div className={'grid'}>
                                    <div className={'grid md:grid-cols-12 gap-[3rem]'}>
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.undergraduate_id}
                                            label={'Qual o seu curso de graduação?'}
                                            className={'md:col-span-6 xs:col-span-1'}
                                            options={coursesOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'undergraduate_id')}
                                            placeholder={'Qual o seu curso de graduação'}
                                        />
                                        <Input
                                            required
                                            className={'md:col-span-6 xs:col-span-1'}
                                            label={'Qual a previsão de formação ou data que formou?'}
                                            name={'course_end_date'}
                                            type={"month"}
                                            value={state.course_end_date}
                                            setValue={v => handleChange(v, 'course_end_date')}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-[3rem] mt-4'}>
                                        {/*<Input
                                    required
                                    className={'md:col-span-6 xs:col-span-1'}
                                    label={'Quais as 3 principais áreas você deseja trabalhar'}
                                    name={'areas_work'}
                                    value={state.areas_work}
                                    setValue={v => handleChange(v, 'areas_work')}
                                />*/}
                                        <InputSelect
                                            isMulti={true}
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            value={areasActivitieSelected}
                                            label={'Quais as 3 principais áreas você deseja trabalhar'}
                                            className={'md:col-span-6 xs:col-span-1'}
                                            options={areasActivitieOptions}
                                            setValue={(e: any) => handleChangeSelect(e)}
                                            placeholder={'Quais as 3 principais áreas você deseja trabalhar'}
                                            isOptionDisabled={areasActivitieSelected.length >= 3}
                                        />
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.perfil}
                                            label={'Você se considera um perfil'}
                                            className={'md:col-span-6 xs:col-span-1'}
                                            options={perfilOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'perfil')}
                                            placeholder={'Você se considera um perfil'}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-[3rem] mt-4'}>
                                        <Input
                                            required
                                            className={'md:col-span-6 xs:col-span-1'}
                                            label={'Qual a maior dificuldade da sua carreira hoje?'}
                                            name={'difficulty_career'}
                                            value={state.difficulty_career}
                                            setValue={v => handleChange(v, 'difficulty_career')}
                                        />
                                        <Input
                                            required
                                            className={'md:col-span-6 xs:col-span-1'}
                                            label={'Se você pudesse elencar 1 único ponto forte seu hoje, qual seria?'}
                                            name={'strong_point'}
                                            value={state.strong_point}
                                            setValue={v => handleChange(v, 'strong_point')}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-[3rem] mt-4'}>
                                        <Input
                                            required
                                            className={'md:col-span-6 xs:col-span-1'}
                                            label={'Se você pudesse elencar 1 único ponto desenvolvimento seu hoje, qual seria?'}
                                            name={'weak_point'}
                                            value={state.weak_point}
                                            setValue={v => handleChange(v, 'weak_point')}
                                        />
                                        <Input
                                            required
                                            className={'md:col-span-6 xs:col-span-1'}
                                            label={'Qual o seu objetivo profissional  para os próximos 5 anos?'}
                                            name={'professional_goal_five'}
                                            value={state.professional_goal_five}
                                            setValue={v => handleChange(v, 'professional_goal_five')}
                                        />

                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-[3rem] mt-4'}>
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.idea_finish_graduation}
                                            label={'Ao finalizar sua graduação você'}
                                            className={'md:col-span-12 xs:col-span-1'}
                                            options={finishGraduationOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'idea_finish_graduation')}
                                            placeholder={'Ao finalizar sua graduação você'}
                                        />
                                    </div>
                                </div>
                                <div className={'mt-4 mb-5 pd-4'}>
                                    <Button
                                        className={'flex w-[200px] gap-2 border border-[#1C1C24] bg-[#2C2C36] text-[#FFF]'}
                                        type={'submit'}
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
            }
        </div>
    )
}