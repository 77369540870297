import noImage from "../../../assets/img/no-image-icon.jpg";
import Str from "../../../helpers/Str";
import Modal from "../../modal/Modal";
import useModal from "../../../hooks/useModal";
export default function CardNews({news, big}: any) {
    const { modalProps, open, close } = useModal();

    return(
        <>
            {
                big ?
                    <div onClick={() => open()} className={'flex relative md:w-[360px] xs:w-full p-5 border rounded-lg  p-3 gap-2 hover:opacity-75 hover:cursor-pointer'}>
                        <img className={'w-[90px] h-[90px] border  rounded-[10px]'} src={news.url_image_path ? news.url_image_path : noImage}/>
                        <div className={'flex flex-col w-auto justify-center'}>
                            <div className={'text-[16px] text-[#263465] font-bold'}>
                                {news.title.length > 25 ? news.title.substr(0, 60)+'...' : news.title}
                            </div>
                            <div className={'text-[14px] text-[#9299B2]'}>
                                {Str.convertDateStringComplet(news.created_at)}
                            </div>
                        </div>
                    </div>
                    :
                    <div onClick={() => open()} className={'flex p-3 gap-2 hover:opacity-75 hover:cursor-pointer'}>
                        <img className={'w-[50px] h-[50px] border  rounded-[10px]'} src={news.url_image_path ? news.url_image_path : noImage}/>
                        <div className={'flex flex-col'}>
                            <div className={'text-[14px] text-[#263465] font-bold'}>
                                {news.title.length > 38 ? news.title.substr(0, 38)+'...' : news.title}
                            </div>
                            <div className={'text-[12px] text-[#9299B2]'}>
                                {Str.convertDateStringComplet(news.created_at)}
                            </div>
                        </div>
                    </div>
            }



            <Modal
                size={'mdm'}
                title={news.title}
                {...modalProps}
            >
                {
                    news.url_image_path ?
                    <div className={'flex w-full justify-center'}>
                        <img className={'max-w-[500px]'} src={news.url_image_path}/>
                    </div>
                    :
                    ''
                }
                <div className={'text-[17px] mt-5 mb-5'} dangerouslySetInnerHTML={{__html: news.news}}>

                </div>

            </Modal>
        </>
    )
}